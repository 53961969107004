import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/daya_nail_story_large.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(
                    process.env.SIGNUP_URL,
                  );
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const NailStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="Customer story: How Camelo Helps This Nail Salon Owner Manage Her Business and Time"></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30 pb-lg-15">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How Camelo Helps This Nail Salon Owner Manage Her Business
                    and Time
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section bg-image py-28 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
          `}
        />

        <div className="pb-2 pb-lg-9 pt-13 pt-lg-24 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    Daya Nail is a small nail salon located in the heart of
                    Wisconsin. Daya Nail often welcomes 30-40 customers each
                    day. With 10 shift-based staff who constantly swap and
                    change shifts, the salon struggled to keep track of the
                    available employees for each shift, as well as the total
                    work hour of each staff. After using Camelo, Mrs. Dayana
                    Nguyen, the owner, confirmed that she now has more time for
                    herself and her family instead of spending them on
                    scheduling and managing work hours.
                  </p>
                  <h4 className="pt-8 pb-4">
                    The cruel reality and a dream to change
                  </h4>
                  <p className="gr-text-8 text-black">
                    Dayana Nguyen has been in the nail industry for 10 years.
                    When she first came to the US, she worked at a small nail
                    salon, just like most of her Vietnamese friends. That salon
                    was where she learned manicure and pedicure.
                  </p>
                  <p className="gr-text-8 text-black">
                    "The owner gave me a job, but never cared for my well-being.
                    We worked in a small room, breathed in harmful chemicals,
                    and always worked overtime. It was fine because earning lots
                    of money was what I wanted," Dayana shares.
                  </p>
                  <p className="gr-text-8 text-black">
                    She continues, "I dreamed of opening my own salon. I dreamed
                    of running a place that cares for the well-being of staff
                    while giving customers the beauty they want. I worked day
                    and night and even different jobs to earn money."
                  </p>

                  <h4 className="pt-8 pb-4">
                    The very first steps to achieving the dream
                  </h4>
                  <p className="gr-text-8 text-black">
                    With the help of her uncle and the money Dayana saved, Daya
                    Nail was opened.
                  </p>
                  <p className="gr-text-8 text-black">
                    "My staff includes women of color and of poverty, and the
                    co-workers from my previous jobs. I taught them everything I
                    knew about nails. Many of them were middle-aged and
                    low-tech. Because they couldn't communicate well in English,
                    they were fooled and used by crafty employers," says Dayana.
                  </p>
                  <p className="gr-text-8 text-black">
                    It was difficult at first. There were days when 0 customer
                    walked in. Dayana and her staff tried to introduce the salon
                    to all acquaintances and friends, hoping it would spread the
                    world. They started seeing more and more customers entering
                    their doors.
                  </p>
                  <p className="gr-text-8 text-black">
                    "I didn't know how. All I cared about was to serve anyone
                    coming in with a friendly and caring attitude," Dayana says.
                    "Finally, there were days when we served up to 50-60
                    customers. It was huge to me, to us."
                  </p>

                  <h4 className="pt-8 pb-4">
                    Things started to take off, but got difficult as well
                  </h4>
                  <p className="gr-text-8 text-black">
                    Dayana often planned the weekly work schedule ahead in her
                    big notebook. "I wrote down shifts and names, stroke through
                    when there were changes. Then I copied the schedule to
                    another piece of paper and stuck it on the salon wall every
                    weekend," says Dayana.
                  </p>
                  <p className="gr-text-8 text-black">
                    Her staff would then take photos of the schedule. Some
                    jotted down their shifts in their own notebooks. The
                    schedule changed every week, so she had to repeat the
                    process weekly.
                  </p>
                  <p className="gr-text-8 text-black">
                    It was fine for Dayana until busy months came. Customers
                    flooded in, while her staff started to ask for shift swaps
                    and time off. She had to manage everything at the salon,
                    from working with her staff, maintaining tools, and product
                    restocking to handling customers' complaints and questions.
                  </p>
                  <p className="gr-text-8 text-black">
                    Keeping track of staff's shifts, hours and time off started
                    to take too much time for Dayana. "I didn't want my staff to
                    exhaust themselves out by working overtime, but I myself
                    worked overtime almost every day to keep the business
                    running," Dayana shares. Hiring someone to help could be
                    easier, but she didn't have enough budget for it.
                  </p>
                  <p className="gr-text-8 text-black">
                    "I was stressed, exhausted, and frustrated. I became the
                    irritated boss, mom and wife," she says.
                  </p>

                  <h4 className="pt-8 pb-4">
                    The needed assistance of a family member and Camelo
                  </h4>
                  <p className="gr-text-8 text-black">
                    "My son saw me struggling, and he told me about something
                    called "scheduling app", or "employee management software"
                    that he found online. I wasn't very fond of technology, but
                    I thought, "Heck, why not?"," Dayana laughs.
                  </p>
                  <p className="gr-text-8 text-black">
                    With the help of her son, they browsed through different
                    websites, product lists, and reviews to find an option for
                    their needs and budget. "Then we found Camelo. It seemed to
                    have all the features we needed, so we decided to give it a
                    try," she smiles.
                  </p>
                  <p className="gr-text-8 text-black">
                    She slowly learned to assign shifts and tasks on her phone.
                    It was easier than she thought. It took a bit of time to set
                    things up at first. "Once everything is in place, I just
                    need 15-20 minutes every week to do scheduling, task
                    management, and time off approvals. The process used to take
                    me 2 hours every week," says she.
                  </p>
                  <p className="gr-text-8 text-black">
                    The owner even shows her staff how to use the app. "They can
                    quickly understand the features and remember what buttons to
                    touch," says Dayana. She loves the simplicity and
                    "intuitivity" of Camelo.
                  </p>
                  <p className="gr-text-8 text-black">
                    <blockquote className="blockquote mb-0 card-body">
                      <p className="text-black pt-4 gr-text-6 lead">
                        "I don't have to write and copy things manually anymore.
                        My staff can access their schedules and task lists on
                        their phones, which is quick and convenient."
                      </p>
                      <footer className="blockquote-footer">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="gr-text-10 gr-color-blackish-blue-opacity-8">
                            <div>
                              Dayana Nguyen
                              <span> (Owner)</span>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </blockquote>
                  </p>

                  <h4 className="pt-8 pb-4">
                    A happier family life and personal life
                  </h4>
                  <p className="gr-text-8 text-black">
                    "I feel like the whole journey of opening and managing my
                    own salon is so short when putting into words," says Dayana.
                    But it's a long process with lots of trials and errors.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      "I'm happy that I've found the right people, tools, and
                      resources to run my dream business"
                    </p>
                    <footer className="blockquote-footer">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="gr-text-10 gr-color-blackish-blue-opacity-8">
                          <div>
                            Dayana Nguyen
                            <span> (Owner)</span>
                          </div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    When everything is managed properly, Dayana has more time
                    for herself and her family.{" "}
                    <em>"I'm happier than ever,"</em> she laughs.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default NailStory;
